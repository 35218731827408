import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap-v5'
import { useFirstRender } from '../hooks/useFirstRender'
import firebase from 'firebase/app';
import 'firebase/firestore';

const AddProtection = () => {

  const firstRender = useFirstRender()
  const db = firebase.firestore()
  const protectionsRef = db.collection('protection')
  const [show, setShow] = useState(false);
  const [newProtection, setNewProtection] = useState({
    type: '',
    powermax: 0,
    powermin: 0,
    price: 0
  })

  const resetNewProtection = () => {
    setNewProtection({
      type: '',
    powermax: 0,
    powermin: 0,
    price: 0
    })
  }

  const handleClose = () => {
    resetNewProtection()
    setShow(false)
  }
  const handleShow = () => setShow(true)

  const handleAdd = () => {
    
    protectionsRef.add(newProtection)
    resetNewProtection()
    setShow(false)
    // console.log(newProtection)
  }

  useEffect(() => {
    if (firstRender) return
    // console.log(document.getElementById('available')?.checked)
    // console.log(newProtection)
  }, [newProtection])
  // db.collection('moduls').add(data)
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Dodaj
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Dodaj</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Typ</span>
          <input type="text" className="form-control" placeholder="Typ" id='type'
            onChange={(e) => setNewProtection({...newProtection, type: e.target.value})} required/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Zakres mocy</span>
          <input type="number" className="form-control" placeholder="od" id='powermin'
            onChange={(e) => setNewProtection({...newProtection, powermin: 1*e.target.value})}/>
          <input type="number" className="form-control" placeholder="do" id='powermax'
            onChange={(e) => setNewProtection({...newProtection, powermax: 1*e.target.value})}/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Cena</span>
          <input type="number" className="form-control" placeholder="Cena" id='price'
            onChange={(e) => setNewProtection({...newProtection, price: 1*e.target.value})}/>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Anuluj
          </Button>
          <Button variant="primary" onClick={handleAdd}>
            Dodaj
          </Button>
        </Modal.Footer>
      </Modal>

      {/* show btn Modal */}
{/* <button type="button" classNameName="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button>


<div classNameName="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div classNameName="modal-dialog">
    <div classNameName="modal-content">
      <div classNameName="modal-header">
        <h5 classNameName="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" classNameName="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div classNameName="modal-body">
        ...
      </div>
      <div classNameName="modal-footer">
        <button type="button" classNameName="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" classNameName="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div> */}
    </>
  );
};

export default AddProtection;
