import React, { useContext, useState } from 'react'
import { DataContext } from '../../providers/dataProvider'
import { OfferContext } from '../../providers/offerProvider'
import Spinner from '../../components/spinner/spinner'
import Modules from '../../components/calculator/modules.component'
import Power from '../../components/calculator/power.component'
import RoofType from '../../components/calculator/roofType.component'
import FinalTune from '../../components/calculator/finalTune.component'
import VatTax from '../../components/calculator/vatTax.component'
import Inverters from '../../components/calculator/inverters.component'
import Opti from '../../components/calculator/opti.component'
import Price from '../../components/calculator/price.component'
import { Navigation } from './index'
import { useFirstRender } from '../../hooks/useFirstRender'
import AddOffer from '../../CSVToFirestore/AddOffer'

const Calculator = () => {

  const {moduls,
    inverters,
    constructions,
    installation,
    protection,
    settings,
    opti,
    loading} = useContext(DataContext)


  const offerContext = useContext(OfferContext)
  const state = offerContext.offerState
  const dispatch = offerContext.offerDispatch
  const firstRender = useFirstRender()

  // const [selModulePower, setSelModulePower] = useState(moduls.find((item) => item.id == state.moduleId).power)

  // React.useEffect(() => {
  //   setSelModulePower(moduls.find((item) => item.id == state.moduleId).power)
  //   dispatch({type: 'setModuleCount', payload:(Math.floor(state.requestedPower * 1000 / selModulePower))})
  //   dispatch({type: 'setTruePower', payload: (selModulePower*state.moduleCount)/1000})
  // }, [state.moduleId, state.requestedPower, selModulePower])

  // React.useEffect(() => {
  //   dispatch({type: 'setTruePower', payload: (selModulePower*state.moduleCount/1000)})
  //   console.log('truePower: '+state.truePower)
  // }, [state.moduleCount, state.moduleId])


  if (loading) return <Spinner/>

  // if (firstRender) {
  //   console.log('Pierwszy render')
  // }
  // console.log(moduls)
  // console.log('loading: '+loading)
  // console.log('ID wybranego modułu: '+offerContext.offerState.moduleId)

  // const modulePower = moduls.find((item) => item.id == offerContext.offerState.moduleId).power
  // console.log('moc modułu: '+modulePower)

  

  return (
    <div className="mx-auto d-flex flex-column justify-content-center text-end">
      <Navigation/>
      <Power/>
      <Modules/>
      <RoofType/>
      <FinalTune/>
      <VatTax/>
      <Inverters/>
      <Opti/>
      <Price/>
      <AddOffer/>
    </div>
  )
}

export default Calculator
