import React, {useContext} from 'react';
import { OfferContext } from '../../providers/offerProvider'

const Power = () => {
  const offerContext = useContext(OfferContext)
 


  return (
    <>
      <div className="mx-auto m-2 ">
        <label className="mx-auto pe-2">Żądana moc (kWp)</label>
        <input
          type="number"
          value={offerContext.offerState.requestedPower!=0 ? offerContext.offerState.requestedPower : ''}
          onChange={(e) => {e.target.value>50 ? 
            offerContext.offerDispatch({type: 'setRequestedPower', payload:50}) :
            offerContext.offerDispatch({type: 'setRequestedPower', payload:e.target.value});
          }}
        />
      </div>
     
    </>
  );
};

export default Power;
