import React, {useContext, useEffect} from 'react';
import { OfferContext } from '../../providers/offerProvider'
import { DataContext } from '../../providers/dataProvider'
import { UserContext } from '../../providers/UserProvider';


const Modules = () => {
  const offerContext = useContext(OfferContext)
  const state = offerContext.offerState
  const dispatch = offerContext.offerDispatch

  const { 
    moduls,
    inverters,
    constructions,
    installation,
    protection,
    settings,
    opti, } = useContext(DataContext)

  const user = useContext(UserContext).user
  const division = user.division
  
      const share = parseFloat(settings[division]?.share) ?? 0
      const fixedMargin = parseFloat(settings.constmargin) ?? 0
      const modulePrice = parseFloat(moduls.find((item) => item.id == state.moduleId)?.price) ?? 0
      const inverterPrice = parseFloat(inverters.find((item) => item.id == state.inverterId)?.price) ?? 0
      const constructionPrice = parseFloat(constructions.find((item) => item.id == state.roofType)?.price) ?? 0
      const optiPrice = parseFloat(opti.find((item) => item.id == state.optiId)?.price) ?? 0
      const installationPrice = parseFloat(installation.find((item) => item.powermax > state.truePower && item.powermin <= state.truePower)?.price) ?? 0
      const extraPrice = protection
        .filter((item) => item.powermax > state.truePower && item.powermin <= state.truePower)
        .reduce((prev, cur) => {
          return prev + cur?.price
        }, 0) ?? 0
      // console.log(state.roofType)
      // console.log(state.inverterBrand)
      // console.log(inverterPrice)
      // console.log(user)
  
      const zeroPrice = (
        (modulePrice * state.moduleCount) +
        inverterPrice +
        (constructionPrice * state.moduleCount) +
        (installationPrice * state.truePower)+
        (optiPrice * state.optiCount)+
        extraPrice
      )*(1+fixedMargin)

      const nettoPrice = (zeroPrice*(1+state.margin/100)).toFixed(2)
      const commission = (zeroPrice*state.margin/100*share).toFixed(2)

      // Testowe consolelogi
    // console.log('-------------------------------------')
    // console.log('Moc instalacji: '+state.truePower)
    // console.log('Cena za moduł: '+modulePrice)
    // console.log('Liczba modułów: '+state.moduleCount)
    // console.log('Cena za falownik: '+inverterPrice)
    // console.log('Cena za konstrukcję na 1 moduł: '+constructionPrice)
    // console.log('Cena za montaż - kwota za 1kWh: '+installationPrice)
    // console.log('Cena za optymalizator: '+optiPrice)
    // console.log('Liczba optymalizatorów: '+state.optiCount)
    // console.log('dodatki: '+JSON.stringify(extraPrice))
    // console.log('marża: '+state.margin+'% = '+(zeroPrice*state.margin/100).toFixed(2)+' zł')
    // console.log('prowizja: '+share*100+'% = '+commission+' zł')

  useEffect(() => {

    dispatch({type: 'setNettoPrice', payload: nettoPrice})
    dispatch({type: 'setCommission', payload: commission})
    // console.log('netto price set: '+nettoPrice)
  }, [nettoPrice])

  return (
    <div className="mx-auto d-flex flex-column bd-highlight m-3">
      <table>
        <tbody>
        <tr>
          <td className='text-end fw-bold pe-2'>cena netto:</td>
          <td className='text-start'>{state.nettoPrice} zł</td>
        </tr>
        <tr>
          <td className='text-end fw-bold pe-2'>VAT {state.vatTax*100}%:</td>
          <td className='text-start'> {(state.nettoPrice * state.vatTax).toFixed(2)} zł</td>
        </tr>
        <tr>
          <td className='text-end fw-bold pe-2'>cena brutto:</td>
          <td className='text-start'> {(state.nettoPrice * (1+state.vatTax)).toFixed(2)} zł</td>
        </tr>
        </tbody>
      </table>
    </div>
  )
};

export default Modules;
