import React, {useEffect, useContext, useState} from 'react';
import { OfferContext } from '../../providers/offerProvider'
import { DataContext } from '../../providers/dataProvider'
import { useFirstRender } from '../../hooks/useFirstRender';

const Opti = () => {

  const offerContext = useContext(OfferContext)
  const { opti } = useContext(DataContext)
  const state = offerContext.offerState
  const dispatch = offerContext.offerDispatch
  const seOptiDefId = opti.find((item) => item.brand == 'Solaredge').id
  const noneOptiId = opti.find((item) => item.brand == '').id

  const firstRender = useFirstRender()
  const [disabledButton, setDisabledButton] = useState(false)
  
  useEffect(() => {
    if (firstRender) return
    if (state.inverterBrand == 'Solaredge') {
      dispatch({type: 'setOptiBrand', payload: 'Solaredge'})
      dispatch({type: 'setOptiId', payload: seOptiDefId})
      dispatch({type: 'setOptiCount', payload: state.moduleCount})
      setDisabledButton(true)
    }
    else {
      setDisabledButton(false)
      dispatch({type: 'setOptiBrand', payload: opti.find((item)=>item.id==noneOptiId).brand  })
      dispatch({type: 'setOptiId', payload: noneOptiId})

    }
      // console.log(state.optiBrand)
      // console.log(state.inverterBrand)
  }, [state.inverterBrand])

  const optiModels = opti
  .filter((model) => model.brand === state.optiBrand)
  .map((item) => {
    return (
      <option key={item.id} value={item.id}>
        {item.brand} {item.model}
      </option>
    );
  });
 
  const handleOptiCount = (value) => {
    if (state.inverterBrand == 'Solaredge') return
    const optiModel = opti.find((item) => item.id == state.optiId).model
    if (optiModel == 'brak optymalizatorów') { 
      setDisabledButton(true)
      dispatch({type: 'setOptiCount', payload: 0})
    } else {
      setDisabledButton(false)
      dispatch({type: 'setOptiCount', payload: value})
    }
  }

  useEffect(()=>{handleOptiCount(state.optiCount)},[state.optiId, state.inverterBrand])
  return (
    <>
      {/* <div className="mx-auto m-2">
      <table className="mx-auto fw-bold mb-2">
        <tbody>
          <tr>
            <td className='text-end pe-2'>
              Optymalizatory
            </td>
            <td className='text-start'>
              {state.optiBrand}
            </td>
          </tr>
        </tbody>
      </table>
      </div> */}
      <div className="mx-auto m-2">
        <label className="mx-auto pe-2">Model optymalizatora</label>
        <select
          value={state.optiId}
          onChange={(e) => {
            dispatch({
              type: 'setOptiId',
              payload: e.target.value
          })
          }}
        >
        
          <optgroup label="optymalizatory do wyboru">
            <option value="" disabled hidden />
            {optiModels}
          </optgroup>
        </select>
      </div>
      <div className="mx-auto m-2">
      <label className="mx-auto pe-2">Liczba optymalizatorów</label>
      <input type="number" value={state.optiCount} className='mb-2' min={0}
      onChange={(e) => handleOptiCount(e.target.value)}
      
      />
      <div className='d-flex justify-content-center'>
        <button
            className="btn btn-outline-warning mx-2 fw-bold"
            onClick={() => {
              state.optiCount>0 ? dispatch({type: 'setOptiCount', payload: 1*state.optiCount -1})
              : dispatch({type: 'setOptiCount', payload: 0})

            }}
            disabled={disabledButton}
          >
            odejmij
        </button>
        <button
            className="btn btn-outline-warning mx-2 fw-bold"
            onClick={() => {
              state.optiCount<state.moduleCount ? dispatch({type: 'setOptiCount', payload: 1*state.optiCount +1})
              : dispatch({type: 'setOptiCount', payload: state.moduleCount})
            }}
            disabled={disabledButton}
          >
            dodaj
        </button>
      </div>
      </div>
    </>
  );
};

export default Opti;
