import React, {useContext} from 'react';
import { OfferContext } from '../../providers/offerProvider'


const Commission = () => {
  const offerContext = useContext(OfferContext)
  const state = offerContext.offerState
  

  

  return (
    <div className="mx-auto d-flex flex-column bd-highlight m-3">
      
      
      <table>
        <tbody>
        <tr>
          <td className='text-end fw-bold pe-2'>cena za 1kWh netto:</td>
          <td className='text-start'>{(state.nettoPrice/state.truePower).toFixed(2)} zł</td>
        </tr>
        <tr>
          <td className='text-end fw-bold pe-2'>cena za 1kWh brutto:</td>
          <td className='text-start'>{(state.nettoPrice*(1+state.vatTax)/state.truePower).toFixed(2)} zł</td>
        </tr>
        <tr>
          <td className='text-end fw-bold pe-2 pt-3'>prowizja:</td>
          <td className='text-start pt-3'>{state.commission} zł</td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Commission;
