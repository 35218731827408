import React, { useContext, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { DataContext } from '../../../providers/dataProvider'


const DatabaseInstallation = () => {
  const [moreInfo, setMoreInfo] = useState({});
  const { installation } = useContext(DataContext);
  const installationRef = firebase.firestore().collection('installation')

  const [edit, setEdit] = useState({})

  const toggleEditShow = (id) => {
      setEdit(installation.find((item) => item.id == id))
      setMoreInfo((prevMoreInfo) => ({
         [id]: !prevMoreInfo[id],
      }));
  };

  function sortItems(a, b) {
   if (a.powermin < b.powermin) {
     return -1;
   }
   if (a.powermin > b.powermin) {
     return 1;
   }
   return 0;
 }
//   const [newPrice, setNewPrice] = useState()

//   const handlePriceChange = (itemeId) => {
//     const model = installation.find((item) => item.id == itemeId).model
//     console.log(model, newPrice)
//     console.log(installationsRef.doc(itemeId).price)
//   }

  const installationsList = installation
   .sort(sortItems)
   .map((item) => {
    return (
      <>
        <tr
         
         className="container"
        >
         <td className="col m-2">{item.powermin}</td>
         <td className="col m-2">{item.powermax}</td>
         <td className="col m-2">{item.price} zł</td>
         <td className='col'>
            <button
               className='btn btn-outline-primary btn-sm'
               onClick={() => {
                  toggleEditShow(item.id);
                }}>
                  edytuj
            </button>
         </td>
         {/* <td className='col'>
            <button
               className='btn btn-outline-danger btn-sm'>
                  usuń
            </button>
         </td> */}
        </tr>
        {moreInfo[item.id] ? (
         <tr>
            <td>
               <input type="number" 
                  className='form-control form-control-sm'
                  onChange={(e) => setEdit({...edit, powermin: 1*e.target.value})}
                  value={edit.powermin}/>
            </td>
            <td>
               <input type="number" 
                  className='form-control form-control-sm'
                  onChange={(e) => setEdit({...edit, powermax: 1*e.target.value})}
                  value={edit.powermax}/>
            </td>
            <td>
               <input type="number" 
                  className='form-control form-control-sm'
                  onChange={(e) => setEdit({...edit, price: 1*e.target.value})}
                  value={edit.price}/>
            </td>
            <td>
               <button className='btn btn-outline-success'
                  onClick={() => {installationRef.doc(item.id).set(edit)
                    toggleEditShow(item.id);
                     }}
               >aktualizuj</button>
            
               <button className='btn btn-outline-secondary'
                  onClick={() => {
                     toggleEditShow(item.id);
                   }}
               >anuluj</button>
            </td>
         </tr>
        ) : null}
      </>
    );
  });

  return (
    <>
      {/* <AddToFirestore collection="installation" /> */}
      <table className="table table-striped table-hover w-auto mx-auto">
        <thead>
          <tr>
          {/* <th scope="col">TYP</th> */}
          <th scope="col">MOC - OD</th>
          <th scope="col">MOC - DO</th>
          <th scope="col">CENA</th>
          <th scope='col'></th>
          </tr>
        </thead>
        <tbody>{installationsList}</tbody>
      </table>
    </>
  );
};

export default DatabaseInstallation;
