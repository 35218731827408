import React, { useState, useEffect, useContext } from 'react'
import { Button, Modal } from 'react-bootstrap-v5'
import { useFirstRender } from '../hooks/useFirstRender'
import { OfferContext } from '../providers/offerProvider'
import { DataContext } from '../providers/dataProvider'
import { UserContext } from '../providers/UserProvider'
import OfferData from '../components/profile/offerData.component'
import Price from '../components/calculator/price.component'
import PDFFillForm from '../components/pdfFillForm/PDFFillForm'
import firebase from 'firebase/app';
import 'firebase/firestore';

const AddOffer = () => {

  const firstRender = useFirstRender()
  const db = firebase.firestore()
  const offerRef = db.collection('offers')
  const [show, setShow] = useState(false);
  const state = useContext(OfferContext).offerState
  const user = useContext(UserContext).user
  const userRef = db.collection('users').doc(user.uid)
  const [curOffer, setCurOffer] = useState(1*user.curOffer)
  // console.log(user)
  const {moduls,
    inverters,
    constructions,
    installation,
    protection,
    settings,
    opti,
    loading} = useContext(DataContext)


  const getCurrentDate = () => {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${date}-${month<10?`0${month}`:`${month}`}-${year}`
    }

  const getCurrentYear = () => {

    let newDate = new Date()
    let year = newDate.getFullYear();
    
    return `${year}`
    }

  const [newOffer, setNewOffer] = useState({
    // client: {
    //   name: '',
    //   address: {
    //     street: '',
    //     number: '',
    //     city: '',
    //     postalCode: '',
    //     postalCity: ''
    //   },
    //   email: '',
    //   phone: ''
    // },
    // installation: {
    //   power: state.truePower,
    //   module: moduls.find((item) => item.id == state.moduleId),
    //   inverter: inverters.find((item) => item.id == state.inverterId),
    //   construction: constructions.find((item) => item.id == state.roofType),

    //   address: {
    //     street: '',
    //     number: '',
    //     city: '',
    //     postalCode: '',
    //     postalCity: ''
    //   },
    // }
  })

  const resetNewOffer = () => {
    setNewOffer({
      date: getCurrentDate(),
      offerNumber: user.prefix +'/O/'+ curOffer +'/'+ getCurrentYear(),
      client: {
        name: '',
        address: {
          street: '',
          number: '',
          city: '',
          postalCode: '',
          postalCity: ''
        },
        email: '',
        phone: ''
      },
      installation: {
        power: (state.truePower).toString(),
        module: moduls.find((item) => item.id == state.moduleId),
        inverter: inverters.find((item) => item.id == state.inverterId),
        construction: constructions.find((item) => item.id == state.roofType),

        address: {
          street: '',
          number: '',
          city: '',
          postalCode: '',
          postalCity: ''
        },
        nettoPrice: (1*state.nettoPrice).toFixed(2).toString(),
        vat: (state.vatTax*100).toString()+'%',
        vatValue: (state.nettoPrice*state.vatTax).toFixed(2).toString(),
        bruttoPrice: (state.nettoPrice*(1+state.vatTax)).toFixed(2).toString()
      },
      salesman: {
        name: user.displayName,
        email: user.email,
        phone: user.phone,
        web: 'www.voltspot.pl'
      }
    })
  }

  const handleClose = () => {
    // resetNewOffer()
    setShow(false)
  }
  const handleShow = () => {
    resetNewOffer()
    setShow(true)
  }

  const handleAdd = () => {
    
    // setNewOffer(() => {
    // })
    userRef.update({curOffer: 1*curOffer+1})
    setCurOffer(curOffer+1)
    offerRef.add(newOffer)
    // resetNewOffer()
    setShow(false)
    // console.log(newOffer)
  }

  const handleAddress = () => {
    setNewOffer({...newOffer, installation: {...newOffer.installation, address: newOffer.client.address}})
  }

  useEffect(() => {
    if (firstRender) return
    // console.log(document.getElementById('available')?.checked)
    // console.log(newOffer)
  }, [newOffer])
  // db.collection('moduls').add(data)
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Nowa oferta
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Oferta nr: {user.prefix}/O/{curOffer}/{getCurrentYear()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {/* Dane klienta */}
          <div className='card mb-3'>
            <div className="card-header mb-3">
              Dane klienta
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text w-25">Imię i nazwisko</span>
              <input type="text" className="form-control" placeholder="Imię i nazwisko"
                onChange={(e) => setNewOffer({...newOffer, client: {...newOffer.client, name: e.target.value}})} required/>
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text w-25">Telefon</span>
              <input type="text" className="form-control" placeholder="Numer telefonu"
                onChange={(e) => setNewOffer({...newOffer, client: {...newOffer.client, phone: e.target.value}})} required/>
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text w-25">E-mail</span>
              <input type="text" className="form-control" placeholder="@"
                onChange={(e) => setNewOffer({...newOffer, client: {...newOffer.client, email: e.target.value}})} required/>
            </div>
            <div className="input-group">
              <span className="input-group-text w-25">Adres</span>
              <input type="text" className="form-control" placeholder="ulica"
                onChange={(e) => setNewOffer({...newOffer, client: {...newOffer.client, address: {...newOffer.client.address, street: e.target.value}}})} required/>
              <input type="text" className="form-control" placeholder="numer"
                onChange={(e) => setNewOffer({...newOffer, client: {...newOffer.client, address: {...newOffer.client.address, number: e.target.value}}})} required/>
            </div>
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="miasto"
                onChange={(e) => setNewOffer({...newOffer, client: {...newOffer.client, address: {...newOffer.client.address, city: e.target.value}}})} required/>
              <input type="text" className="form-control" placeholder="kod pocztowy"
                onChange={(e) => setNewOffer({...newOffer, client: {...newOffer.client, address: {...newOffer.client.address, postalCode: e.target.value}}})} required/>
              <input type="text" className="form-control" placeholder="poczta"
                onChange={(e) => setNewOffer({...newOffer, client: {...newOffer.client, address: {...newOffer.client.address, postalCity: e.target.value}}})} required/>
            </div>
          </div>
            {/* Dane instalacji */}
          <div className="card">  
            <div className="card-header mb-3">
              Dane instalacji
            </div>
            <OfferData/>
            <Price/>
            <Button variant="secondary" onClick={handleAddress}>
            Adres j.w.
            </Button>            {/* <div className="input-group mb-3">
              <span className="input-group-text w-25">Imię i nazwisko</span>
              <input type="text" className="form-control" placeholder="Imię i nazwisko" id='name'
                onChange={(e) => setNewOffer({...newOffer, client: {...newOffer.client, name: e.target.value}})} required/>
            </div> */}
            <div className="input-group">
              <span className="input-group-text w-25">Adres</span>
              <input type="text" className="form-control" placeholder="ulica"
                value={newOffer.installation?.address.street ?? ''}
                onChange={(e) => setNewOffer({...newOffer, installation: {...newOffer.installation, address: {...newOffer.installation.address, street: e.target.value}}})} required/>
              <input type="text" className="form-control" placeholder="numer"
                value={newOffer.installation?.address.number ?? ''}
                onChange={(e) => setNewOffer({...newOffer, installation: {...newOffer.installation, address: {...newOffer.installation.address, number: e.target.value}}})} required/>
            </div>
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="miasto"
                value={newOffer.installation?.address.city ?? ''}
                onChange={(e) => setNewOffer({...newOffer, installation: {...newOffer.installation, address: {...newOffer.installation.address, city: e.target.value}}})} required/>
              <input type="text" className="form-control" placeholder="kod pocztowy"
                value={newOffer.installation?.address.postalCode ?? ''}
                onChange={(e) => setNewOffer({...newOffer, installation: {...newOffer.installation, address: {...newOffer.installation.address, postalCode: e.target.value}}})} required/>
              <input type="text" className="form-control" placeholder="poczta"
                value={newOffer.installation?.address.postalCity ?? ''}
                onChange={(e) => setNewOffer({...newOffer, installation: {...newOffer.installation, address: {...newOffer.installation.address, postalCity: e.target.value}}})} required/>
            </div>
            <div className="input-group">
              <span className="input-group-text w-25">Uzyski</span>
              <input type="text" className="form-control" placeholder="nasłonecznienie"
                onChange={(e) => setNewOffer({...newOffer, installation: {...newOffer.installation, irradiation: e.target.value}})} required/>
              <input type="text" className="form-control" placeholder="roczny uzysk"
                onChange={(e) => setNewOffer({...newOffer, installation: {...newOffer.installation, energyYearly: e.target.value}})} required/>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Anuluj
          </Button>
          <Button variant="primary" onClick={handleAdd}>
            Dodaj
          </Button>
          <PDFFillForm data={newOffer} />
        </Modal.Footer>
      </Modal>

      {/* show btn Modal */}
{/* <button type="button" classNameName="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button>


<div classNameName="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div classNameName="modal-dialog">
    <div classNameName="modal-content">
      <div classNameName="modal-header">
        <h5 classNameName="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" classNameName="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div classNameName="modal-body">
        ...
      </div>
      <div classNameName="modal-footer">
        <button type="button" classNameName="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" classNameName="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div> */}
    </>
  );
};

export default AddOffer;
