import React, { useContext, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { DataContext } from '../../../providers/dataProvider'
import AddProtection from '../../../CSVToFirestore/AddProtection'


const DatabaseProtections = () => {
  const [moreInfo, setMoreInfo] = useState({});
  const { protection } = useContext(DataContext);
  const protectionRef = firebase.firestore().collection('protection')

  const [editProtection, setEditProtection] = useState({})

  const toggleEditShow = (id) => {
      setEditProtection(protection.find((item) => item.id == id))
      setMoreInfo((prevMoreInfo) => ({
         [id]: !prevMoreInfo[id],
      }));
  };

  function sortItems(a, b) {
   if (a.type < b.type) {
     return -1;
   }
   if (a.type > b.type) {
     return 1;
   }
   if (a.powermin < b.powermin) {
     return -1;
   }
   if (a.powermin > b.powermin) {
     return 1;
   }
   return 0;
 }
//   const [newPrice, setNewPrice] = useState()

//   const handlePriceChange = (itemeId) => {
//     const model = protection.find((item) => item.id == itemeId).model
//     console.log(model, newPrice)
//     console.log(protectionsRef.doc(itemeId).price)
//   }

  const protectionsList = protection
   .sort(sortItems)
   .map((item) => {
    return (
      <>
        <tr
         
         className="container"
        >
         <td className="col mb-2">{item.type}</td>
         <td className="col m-2">{item.powermin}</td>
         <td className="col m-2">{item.powermax}</td>
         <td className="col m-2">{item.price} zł</td>
         <td className='col'>
            <button
               className='btn btn-outline-primary btn-sm'
               onClick={() => {
                  toggleEditShow(item.id);
                }}>
                  edytuj
            </button>
         </td>
         {/* <td className='col'>
            <button
               className='btn btn-outline-danger btn-sm'>
                  usuń
            </button>
         </td> */}
        </tr>
        {moreInfo[item.id] ? (
         <tr>
            <td>
               <input type="text" 
                  className='form-control form-control-sm'
                  onChange={(e) => setEditProtection({...editProtection, type: e.target.value})}
                  value={editProtection.type}/>
            </td>
            <td>
               <input type="number" 
                  className='form-control form-control-sm'
                  onChange={(e) => setEditProtection({...editProtection, powermin: 1*e.target.value})}
                  value={editProtection.powermin}/>
            </td>
            <td>
               <input type="number" 
                  className='form-control form-control-sm'
                  onChange={(e) => setEditProtection({...editProtection, powermax: 1*e.target.value})}
                  value={editProtection.powermax}/>
            </td>
            <td>
               <input type="number" 
                  className='form-control form-control-sm'
                  onChange={(e) => setEditProtection({...editProtection, price: 1*e.target.value})}
                  value={editProtection.price}/>
            </td>
            <td>
               <button className='btn btn-outline-success'
                  onClick={() => {protectionRef.doc(item.id).set(editProtection)
                    toggleEditShow(item.id);
                     }}
               >aktualizuj</button>
            {/* </td>
            <td> */}
               <button className='btn btn-outline-secondary'
                  onClick={() => {
                     toggleEditShow(item.id);
                   }}
               >anuluj</button>
               <button className='btn btn-outline-danger btn-sm mt-3'
                  onClick={() => protectionRef.doc(item.id).delete().then(() => {alert('usunąłeś pozycję '+item.type+' od mocy '+item.powermin+' do mocy '+item.powermax)})}
               >usuń</button>
            </td>
         </tr>
        ) : null}



      </>
    );
  });

  return (
    <>
      {/* <AddToFirestore collection="protection" /> */}
      <table className="table table-striped table-hover w-auto mx-auto">
        <thead>
          <tr>
            <td><AddProtection/></td>
          </tr>
          <tr>
          <th scope="col">TYP</th>
          <th scope="col">MOC - OD</th>
          <th scope="col">MOC - DO</th>
          <th scope="col">CENA</th>
          <th scope='col' colSpan='2'></th>
          </tr>
        </thead>
        <tbody>{protectionsList}</tbody>
      </table>
    </>
  );
};

export default DatabaseProtections;
