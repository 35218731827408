import React, {useContext} from 'react';
import { OfferContext } from '../../providers/offerProvider'
import { DataContext } from '../../providers/dataProvider'

const Modules = () => {
  const offerContext = useContext(OfferContext)
  const { moduls } = useContext(DataContext)

  function sortModules(a, b) {
    if (a.brand < b.brand) {
      return -1;
    }
    if (a.brand > b.brand) {
      return 1;
    }
    return 0;
  }
  const showModule = moduls
    .filter((item) => item.available == 1)
    .sort(sortModules)
    .map((item) => {
    return (
      <option key={item.id} value={item.id}>        
        {item.brand} | {item.model} | {item.power}Wp
      </option>
    );
  });



  return (
    <>
      <div className="mx-auto m-2">
        <label className="pe-2">Moduł</label>
        <select
          value={offerContext.offerState.moduleId}
          onChange={(e) => {
            offerContext.offerDispatch({
              type: 'setModuleId', 
              payload: e.target.value})
          }}
          
        >
          <optgroup label="moduły do wyboru">
            <option value="" disabled hidden />
            {showModule}
          </optgroup>
        </select>
      </div>
    </>
  );
};

export default Modules;
