export function reducer(state, action) {
  switch (action.type) {
    case 'setRequestedPower':
      return { ...state, requestedPower: action.payload };
    case 'setTruePower':
      return { ...state, truePower: action.payload };
    case 'setModuleId':
      return { ...state, moduleId: action.payload };
    case 'setModuleCount':
      return { ...state, moduleCount: action.payload };
    case 'setInverterBrand':
      return { ...state, inverterBrand: action.payload };
    case 'setInverterId':
      return { ...state, inverterId: action.payload };
    case 'setVatTax':
      return { ...state, vatTax: action.payload };
    case 'setRoofType':
      return { ...state, roofType: action.payload };
    case 'setOptiBrand':
      return { ...state, optiBrand: action.payload };
    case 'setOptiId':
      return { ...state, optiId: action.payload };
    case 'setOptiCount':
      return { ...state, optiCount: action.payload };
    case 'setExtras':
      return { ...state, extras: action.payload };
    case 'setNettoPrice':
      return { ...state, nettoPrice: action.payload };
    case 'setMargin':
      return { ...state, margin: action.payload };
    case 'setCommission':
      return { ...state, commission: action.payload };
    default:
      throw new Error(`invalid aciotn type: ${action.type}`);
  }
}
