import React, {useContext} from 'react';
import { OfferContext } from '../../providers/offerProvider'
import { DataContext } from '../../providers/dataProvider'

const RoofType = () => {
  const offerContext = useContext(OfferContext)
  const { constructions } = useContext(DataContext)

  function sortRoofType(a, b) {
    if (a.type < b.type) {
      return -1;
    }
    if (a.type > b.type) {
      return 1;
    }
    return 0;
  }
  const showRoofTypes = constructions.sort(sortRoofType).map((item) => {
    return (
      <option key={item.id} value={item.id}>
        {item.type}
      </option>
    );
  });


  return (
    <>
      <div className="mx-auto m-2">
        <label className="pe-2">Rodzaj konstrukcji</label>
        <select
          value={offerContext.offerState.roofType}
          onChange={(e) => {
            offerContext.offerDispatch({type: 'setRoofType', payload:e.target.value});
            console.log(offerContext.offerState.roofType)
          }}
        >
          <optgroup label="Rodzaj konstrukcji">
            <option value="" disabled hidden />
            {showRoofTypes}
          </optgroup>
        </select>
      </div>
      {/* <div className="m-2">
        <label className="pe-2">konstrukcja</label>
        <select onChange={(e) => priceDependingOnRoof(e.target.value)}>
          <optgroup label="rodzaj dachu">
            <option value="" selected disabled hidden />
            <option value="flat">dach płaski</option>
            <option value="slant">dach skośny</option>
            <option value="ground">grunt</option>
          </optgroup>
        </select>
      </div> */}
    </>
  );
};

export default RoofType;
