import React, { useState } from 'react';
import firebase from 'firebase/app'
import 'firebase/firestore'
import useFirestoreData from '../../../hooks/useFirestoreData';
// import AddToFirestore from '../../../CSVToFirestore/AddToFirestore';
import AddInverters from '../../../CSVToFirestore/AddInverters'


const DatabaseInverters = () => {
  const [moreInfo, setMoreInfo] = React.useState({});
  const { inverters } = useFirestoreData();
  const db = firebase.firestore()
  const invertersRef = db.collection('inverters')

  const allInverters = inverters
  .map((item) => {
    return (
      <option key={item.id} value={item.brand}>
        {item.brand}
      </option>
    );
  });
  const uniqueInvertersBrand = inverters
  .map((e) => e['brand'])
  .map((e, i, final) => final.indexOf(e) === i && i)
  .filter((obj) => allInverters[obj])
  .map((e) => allInverters[e]);

  function sortinvertes(a, b) {
    if (a.brand < b.brand) {
      return -1;
    }
    if (a.brand > b.brand) {
      return 1;
    }
    if (a.acpower < b.acpower) {
      return -1;
    }
    if (a.acpower > b.acpower) {
      return 1;
    }
    return 0;
  }
  
  function sortbrands(a, b) {
    if (a.props.value < b.props.value) {
      return -1;
    }
    if (a.props.value > b.props.value) {
      return 1;
    }
    return 0;
  }

  const toggleInfoShow = (id) => {
    setMoreInfo((prevMoreInfo) => ({
      [id]: !prevMoreInfo[id],
    }));
  };

  const [selBrand, setSelBrand] = useState('Growatt')
  const [newPrice, setNewPrice] = useState()

  const handlePriceChange = (inverterId) => {
    const model = inverters.find((item) => item.id == inverterId).model
    console.log(model, newPrice)
    invertersRef.doc(inverterId).update({price: parseInt(newPrice)})
  }

  const invertersList = inverters
    .filter((item) => item.brand == selBrand)
    .sort(sortinvertes)
    .map((inverter) => {
    return (
      <>
        <tr
          onClick={() => {
            toggleInfoShow(inverter.id);
          }}
          className="container"
        >
          <td className="col mb-2">{inverter.brand}</td>
          <td className="col m-2">{inverter.model}</td>
          <td className="col m-2">{inverter.acpower / 1000} kW</td>
          <td className="col m-2">{inverter.price} zł</td>
          <td className="col m-2">{inverter.available ? 
            <span class="badge bg-success">Dostępny</span> : 
            <span class="badge bg-secondary">Niedostępny</span>}</td>
        </tr>
        {moreInfo[inverter.id] ? (
          <>
          <tr>
            <td colSpan="5">
              <h5 className="card-title">{inverter.brand}</h5>
              <h6 className="card-subtitle mb-2 text-muted">{inverter.model}</h6>
              <p className="card-text">{inverter.description}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="card-text">Moc AC: {inverter.acpower} W</p>
              <p className="card-text">Max moc DC: {inverter.maxdc} W</p>
            </td>
            <td>
              <p className='card-text'>cena: {inverter.price} zł</p>
              <p className="card-text">gwarancja: {inverter.warranty} lat</p>
            </td>
            <td colSpan='2'>
              <div class="input-group input-group-sm mb-3">
                <input type="number" class="form-control" placeholder="Nowa cena" onChange={(e) => setNewPrice(1*e.target.value)}/>
                <button class="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => handlePriceChange(inverter.id)}>Zmień cenę</button>
              </div>
              {inverter.available ? 
                <button class='btn btn-success'
                  onClick={() =>
                    invertersRef.doc(inverter.id).update({available: false})
                  }>Dostępny</button> : 
                <button class='btn btn-secondary'
                onClick={() =>
                  invertersRef.doc(inverter.id).update({available: true})
                }>Niedostępny</button>}
              </td>
              <td>
                <button className='btn btn-outline-danger btn-sm'
                  onClick={() => invertersRef.doc(inverter.id).delete().then(() => {alert('usunąłeś pozycję '+inverter.brand+' '+inverter.model)})}
               >usuń</button>
              
            </td>
          </tr>
          </>
        ) : null}
      </>
    );
  });

  const invertersBrandMenu = uniqueInvertersBrand
    .sort(sortbrands)
    .map((brand) => {
    return (
    <li className="nav-item">
      <button className='btn btn-outline-secondary nav-link' 
        // onClick={() => console.log(brand.props.value)}
        onClick={() => setSelBrand(brand.props.value)}
      >{brand}</button>
    </li>
    )
  })

  // const handleBrandAvailable = (tof) => {
  //   var batch = db.batch()
  //   invertersList.map((item) => {
  //     batch.update(invertersRef.doc(item.id), {available: tof})
  //   })
  //   batch.commit()
  // }

  return (
    <>
      {/* <AddToFirestore collection="inverters" /> */}
      {/* {uniqueInvertersBrand} */}
      <nav className="navbar nav-tabs navbar-expand-sm">
        <div className="container-fluid justify-content-center">
          <ul className="nav  justify-content-left">
            {invertersBrandMenu}
          </ul>
        </div>
      </nav>

      <table className="table table-striped table-hover w-auto mx-auto">
        <thead>
          <tr>
            <th colSpan='5'>
              <h4>{selBrand}</h4>
            </th>
          </tr>
          <tr>
            <th>
              <AddInverters/>
            </th>
            <th>
              <button class='btn btn-success'
                  onClick={() => inverters.map((item) => {
                    if (item.brand === selBrand) {
                    invertersRef.doc(item.id).update({available: true}) }
                  })
                  }>Dostępne</button>
            </th>
            <th>
              <button class='btn btn-secondary'
                onClick={() => inverters.map((item) => {
                  if (item.brand === selBrand) {
                  invertersRef.doc(item.id).update({available: false}) }
                })
                }>Niedostępne</button>
            </th>
          </tr>
          <tr>
          <th scope="col">MARKA</th>
          <th scope="col">MODEL</th>
          <th scope="col">MOC</th>
          <th scope="col">CENA</th>
          <th scope="col">DOSTĘPNOŚĆ</th>
          </tr>
        </thead>
        <tbody>{invertersList}</tbody>
      </table>
    </>
  );
};

export default DatabaseInverters;
