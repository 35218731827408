import React, { useContext, useState } from 'react'
import { OfferContext } from '../../providers/offerProvider'
import { DataContext } from '../../providers/dataProvider'



const OfferData = () => {
    const offerContext = useContext(OfferContext)
    const state = offerContext.offerState
    const {moduls,
        inverters,
        constructions,
        installation,
        protection,
        settings,
        opti,
        loading} = useContext(DataContext)

    const moduleInfo = moduls
        .find((item) => item.id == state.moduleId)
    
    const inverterInfo = inverters
        .find((item) => item.id == state.inverterId)

    const constructionInfo = constructions
        .find((item) => item.id == state.roofType)

    const optiInfo = opti
        .find((item) => item.id == state.optiId)

    

    return (
        <div className="table-responsive">
            <table className='table table-hover table-sm mx-auto w-auto'>
                <tbody>
                    <tr>
                        <td className='text-end'>
                            Moc instalacji:
                        </td>
                        <td>
                            {state.truePower}kWp
                        </td>
                    </tr>
                    <tr>
                        <td className='text-end'>
                            Moduły:
                        </td>
                        <td>
                            <b>Model:</b> {moduleInfo.brand} {moduleInfo.model}<br/>
                            <b>Moc:</b> {moduleInfo.power}Wp<br/>
                            <b>Liczba:</b> {state.moduleCount}
                        </td>
                    </tr>
                    <tr>
                        <td className='text-end'>
                            Falownik:
                        </td>
                        <td>
                            <b>Model:</b> {inverterInfo.brand} {inverterInfo.model}<br/>
                            <b>Moc AC:</b> {inverterInfo.acpower}W
                        </td>
                    </tr>
                    <tr>
                        <td className='text-end'>
                            Konstrukcja:
                        </td>
                        <td>
                            {constructionInfo.type}
                        </td>
                    </tr>
                    {state.optiCount == 0 ? null : 
                    <tr>
                        <td className='text-end'>
                            Optymalizatory:
                        </td>
                        <td>
                            <b>Model:</b> {optiInfo.brand} {optiInfo.model}<br/>
                            <b>Liczba:</b> {state.optiCount}
                        </td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default OfferData
