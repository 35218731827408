import React, {useState} from 'react';
import Spinner from '../components/spinner/spinner'

import firebase from 'firebase/app';
import 'firebase/firestore';

import { useModuls } from '../providers/modulsProvider';
import { useUsers } from '../providers/usersProvider';
import { useInverters } from '../providers/invertersProvider';
import { useConstructions } from '../providers/constructionsProvider';
import { useInstallation } from '../providers/installationProvider';
import { useProtection } from '../providers/protectionProvider';
import { useSettings } from '../providers/settingsProvider';
import { useOpti } from '../providers/optiProvider';

const db = firebase.firestore();

const useFirestoreData = () => {
  // console.log('POCZĄTEK USEFIRESTORE')
  const [loading, setLoading] = useState(true)
  const [moduls, setModuls] = useModuls();
  const [users, setUsers] = useUsers();
  const [inverters, setInverters] = useInverters();
  const [constructions, setConstructions] = useConstructions();
  const [installation, setInstallation] = useInstallation();
  const [protection, setProtection] = useProtection();
  const [settings, setSettings] = useSettings();
  const [opti, setOpti] = useOpti();
  
 

  React.useEffect(() => {
    // if (moduls.length !== 0) return;
    const modulesRef = db.collection('moduls');
    const usersRef = db.collection('users');
    const invertersRef = db.collection('inverters');
    const constructionsRef = db.collection('constructions');
    const installationRef = db.collection('installation');
    const protectionRef = db.collection('protection');
    const settingsRef = db.collection('settings').doc('settings');
    const optiRef = db.collection('opti');

    // modulesRef.onSnapshot((snapshot) => {
    //   const data = snapshot.docs.map((doc) => ({
    //     id: doc.id,
    //     ...doc.data(),
    //   }));
    //   setModuls(data);
    // });
    modulesRef.onSnapshot((snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setModuls(data);
    });
    usersRef.onSnapshot((snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(data);
    });
    invertersRef.onSnapshot((snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setInverters(data);
    });
    constructionsRef.onSnapshot((snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setConstructions(data);
    });
    installationRef.onSnapshot((snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setInstallation(data);
    });
    protectionRef.onSnapshot((snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProtection(data);
    });
    settingsRef.onSnapshot((snapshot) => {
      const data = snapshot.data();
      // const data = snapshot.data.map((doc) => ({
      //   id: doc.id,
      //   ...doc.data(),
      // }));
      setSettings(data);
    });
    optiRef.onSnapshot((snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setOpti(data);
      setLoading(false)
    });
  //   // if (
  //   //   moduls.length > 0 &&
  //   //   inverters.length > 0 &&
  //   //   constructions.length > 0 &&
  //   //   installation.length > 0 &&
  //   //   protection.length > 0 &&
  //   //   settings.length > 0 &&
  //   //   opti.length > 0
  //   // ) setLoading(false)
  }, []);
  // console.log('KONIEC USEFIRESTORE')
  console.log('Czego tutaj szukasz?')
  return { moduls, users, inverters, constructions, installation, protection, settings, opti, loading };
};
export default useFirestoreData;
