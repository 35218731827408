import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap-v5'
import { useFirstRender } from '../hooks/useFirstRender'
import firebase from 'firebase/app';
import 'firebase/firestore';

const AddInverters = () => {

  const firstRender = useFirstRender()
  const db = firebase.firestore()
  const invertersRef = db.collection('inverters')
  const [show, setShow] = useState(false);
  const [invertersList, setInvertersList] = useState([])
  const [newInverter, setNewInverter] = useState({
    acpower: 0,
    available: true,
    brand: '',
    datasheet: '',
    description: '',
    maxDC: 0,
    model: '',
    mppt: 0,
    ncrfg: '',
    phase: 3,
    price: 0,
    stringsnum: 0,
    warranty: 0
  })

  const resetNewInverter = () => {
    setNewInverter({
      acpower: 0,
      available: true,
      brand: '',
      datasheet: '',
      description: '',
      maxdc: 0,
      model: '',
      mppt: 0,
      ncrfg: '',
      phase: 3,
      price: 0,
      stringsnum: 0,
      warranty: 0
    })
  }

  const handleClose = () => {
    resetNewInverter()
    setShow(false)
  }
  const handleShow = () => setShow(true)

  const handleAddToList = () => {
    if (
      newInverter.brand == '' ||
      newInverter.model == '' ||
      newInverter.acpower == 0 ||
      newInverter.maxdc == 0 ||
      newInverter.phase == 0 ||
      newInverter.mppt == 0 ||
      newInverter.stringsnum == 0 ||
      newInverter.warranty == 0 ||
      newInverter.price == 0
    ) return
    else
    setInvertersList(prev => [...prev, newInverter])
  }

  const handleAdd = () => {
    invertersList.map((item) => 
    invertersRef.add(item))
    // resetNewInverter()
    // setShow(false)
    // console.log(invertersList)
  }

  const handleRemoveFromList = (index) => {
    setInvertersList((invertersList.filter((v, i) => i !== index)))
    // invertersList.splice(invertersList.findIndex(i => i == inverter), 1)
    // console.log('indeks do usunięcia: '+ JSON.stringify(index))
  }

  // const [moreInfo, setMoreInfo] = useState({});

  // const toggleInfoShow = (id) => {
  //   setMoreInfo((prevMoreInfo) => ({
  //     [id]: !prevMoreInfo[id],
  //   }))
  // }

  const displayList = invertersList.map((inverter, index) => {
    return (
      <>
      {/* {index} */}
        <tr
          // onClick={() => {
          //   toggleInfoShow(inverter.model);
          // }}
          className="container table-dark bg-primary"
        >
          <td className="col mb-2 h5">{inverter.brand}</td>
          <td className="col m-2 h6">{inverter.model}</td>
          <td className="col m-2 h6">{inverter.price}zł</td>
          <td><button className='btn btn-secondary' onClick={() => handleRemoveFromList(index)}>usuń</button></td>
        </tr>
        {/* {moreInfo[inverter.model] ? (
          <> */}
          <tr className='bg-light'>
            <td>
              <span className="card-text">moc AC : {inverter.acpower}</span>
            </td>
            <td>
              <span className="card-text">moc DC : {inverter.maxdc}</span>
            </td>
            <td>
              <span className="card-text">gwarancja : {inverter.warranty}</span>
            </td>
          </tr>
          <tr className='bg-light'>
            <td>
              <span className="card-text">fazy : {inverter.phase}</span>
            </td>
            <td>
              <span className="card-text">MPPT : {inverter.mppt}</span>
            </td>
            <td>
              <span className="card-text">stringi : {inverter.stringsnum}</span>
            </td>
          </tr>
          {/* </>
        ) : null} */}
      </>
    )
  })

  useEffect(() => {
    if (firstRender) return
    // console.log(document.getElementById('available')?.checked)
    // console.log(newInverter)
  }, [newInverter])
  // db.collection('moduls').add(data)
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Dodaj falowniki
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Dodaj falowniki</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Producent</span>
          <input type="text" className="form-control" placeholder="Producent" id='brand'
            onChange={(e) => setNewInverter({...newInverter, brand: e.target.value})} required/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Model</span>
          <input type="text" className="form-control" placeholder="Model" id='model'
            onChange={(e) => setNewInverter({...newInverter, model: e.target.value})}/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Moc AC</span>
          <input type="number" className="form-control" placeholder="Moc AC" id='acpower'
            onChange={(e) => setNewInverter({...newInverter, acpower: 1*e.target.value})}/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Max moc DC</span>
          <input type="number" className="form-control" placeholder="Maksymalna moc DC" id='maxdc'
            onChange={(e) => setNewInverter({...newInverter, maxdc: 1*e.target.value})}/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Fazy/mppt/stringi</span>
          <input type="number" className="form-control" placeholder="fazy" id='phase'
            onChange={(e) => setNewInverter({...newInverter, phase: 1*e.target.value})}/>
          <input type="number" className="form-control" placeholder="MPPT" id='mppt'
            onChange={(e) => setNewInverter({...newInverter, mppt: 1*e.target.value})}/>
          <input type="number" className="form-control" placeholder="stringi" id='stringsnum'
            onChange={(e) => setNewInverter({...newInverter, stringsnum: 1*e.target.value})}/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Gwarancja</span>
          <input type="number" className="form-control" placeholder="Gwarancja" id='warranty'
            onChange={(e) => setNewInverter({...newInverter, warranty: 1*e.target.value})}/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Cena</span>
          <input type="number" className="form-control" placeholder="Cena" id='price'
            onChange={(e) => setNewInverter({...newInverter, price: 1*e.target.value})}/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Opis</span>
          <textarea type="text" className="form-control" placeholder="Opis" id='description'
            onChange={(e) => setNewInverter({...newInverter, description: e.target.value})}/>
        </div>
        <div className="input-group mb-3">
          <div className="input-group-text">
            <input className="form-check-input mt-0" type="checkbox" value='' id='available' checked={newInverter.available}
              onChange={(e) => setNewInverter({...newInverter, available: e.target.checked})}/>
          </div>
          <label className="input-group-text w-25" for='available'>Dostępny</label>
        </div>
        
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Anuluj
          </Button>
          <Button variant="primary" onClick={handleAddToList}>
            Dodaj do listy
          </Button>
          {invertersList.length == 0 ? null :
            <>
              <table className='table table-sm table-striped'>
                <tr>
                  <th>Producent</th>
                  <th>Model</th>
                  <th>Cena</th>
                </tr>
                {displayList}
              </table>
              
              <Button variant="primary" onClick={handleAdd}>
                Dodaj listę do bazy danych
              </Button>
            </>}
        </Modal.Footer>
      </Modal>

      {/* show btn Modal */}
{/* <button type="button" classNameName="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button>


<div classNameName="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div classNameName="modal-dialog">
    <div classNameName="modal-content">
      <div classNameName="modal-header">
        <h5 classNameName="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" classNameName="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div classNameName="modal-body">
        ...
      </div>
      <div classNameName="modal-footer">
        <button type="button" classNameName="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" classNameName="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div> */}
    </>
  );
};

export default AddInverters;
