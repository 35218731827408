import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal } from 'react-bootstrap-v5'
import { useFirstRender } from '../hooks/useFirstRender'
import firebase from 'firebase/app';
import 'firebase/firestore';
import { DataContext } from '../providers/dataProvider'

const AddInstallation = () => {

  const firstRender = useFirstRender()
  const db = firebase.firestore()
  const usersRef = db.collection('users')
  const  {settings}  = useContext(DataContext)
  console.log(typeof settings)
  // const divisions = settings
  //   .filter
  const [show, setShow] = useState(false);
  const [newUser, setNewUser] = useState({
    // admin: false,
    // displayName: '',
    // division: '',
    // email: '',
    // phone: '',
    // password: '',
    // margin: 20
  })

  // const [password, setPassword] = useState('')

  const resetNewUser = () => {
    setNewUser({
      admin: false,
      displayName: '',
      division: '',
      email: '',
      phone: '',
      password: '',
      margin: 15,
      prefix: '',
      curOffer: 0,
      curContract: 0
    })
  }

  const handleClose = () => {
    resetNewUser()
    setShow(false)
  }

  const handleShow = () => {
    resetNewUser()
    setShow(true)
  }

  const handleAdd = () => {
    firebase.auth().createUserWithEmailAndPassword(newUser.email, newUser.password).then((userCred) => {
      usersRef.doc(userCred.user.uid).set(newUser)
      resetNewUser()
      setShow(false)
    }).catch((error) => alert(error))
    // console.log(newUser)
  }


  useEffect(() => {
    if (firstRender) return
    // console.log(document.getElementById('available')?.checked)
    // console.log(newUser)
  }, [newUser])
  // db.collection('moduls').add(data)
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Dodaj użytkownika
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Dodaj użytkownika</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Imię i nazwisko</span>
          <input type="text" className="form-control" placeholder="Imię i nazwisko"
            onChange={(e) => setNewUser({...newUser, displayName: e.target.value})} required/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Numer telefonu</span>
          <input type="text" className="form-control" placeholder="Numer telefonu"
            onChange={(e) => setNewUser({...newUser, phone: e.target.value})} required/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">E-mail</span>
          <input type="text" className="form-control" placeholder="e-mail"
            onChange={(e) => setNewUser({...newUser, email: e.target.value})} required/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Hasło</span>
          <input type="text" className="form-control" placeholder="hasło"
            onChange={(e) => setNewUser({...newUser, password: e.target.value})} required/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Dział</span>
          <input type="text" className="form-control" placeholder="division"
            onChange={(e) => setNewUser({...newUser, division: e.target.value})} required/>
        </div>
        
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Marża domyślna</span>
          <input type="number" className="form-control" placeholder="Marża"
            onChange={(e) => setNewUser({...newUser, margin: 1*e.target.value})}/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Inicjały</span>
          <input type="number" className="form-control" placeholder="Inicjały"
            onChange={(e) => setNewUser({...newUser, prefix: e.target.value})}/>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Anuluj
          </Button>
          <Button variant="primary" onClick={handleAdd}>
            Dodaj
          </Button>
        </Modal.Footer>
      </Modal>

      {/* show btn Modal */}
{/* <button type="button" classNameName="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button>


<div classNameName="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div classNameName="modal-dialog">
    <div classNameName="modal-content">
      <div classNameName="modal-header">
        <h5 classNameName="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" classNameName="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div classNameName="modal-body">
        ...
      </div>
      <div classNameName="modal-footer">
        <button type="button" classNameName="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" classNameName="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div> */}
    </>
  );
};

export default AddInstallation;
