import React, {useState, useContext} from 'react'
import { PDFDocument, popGraphicsState} from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'
import download from 'downloadjs'

const PDFFillForm = (props) => {
    const data = props.data
    const makePDF = async () => {
    // const formUrl = 'https://storage.googleapis.com/voltspot-aa420.appspot.com/datasheets/moduls/BAUER%20-%20BS-6MHB5-EL-BLACK%20-%20330Wp.pdf'
    const formUrl = 'https://storage.googleapis.com/voltspot-aa420.appspot.com/offerTemplatev3.pdf'
    // const formUrl = 'https://pdf-lib.js.org/assets/dod_character.pdf'
    const formPdfBytes = await fetch(formUrl)
        .then(res => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(formPdfBytes)

    // Fetch the Ubuntu font
    const fontUrl = 'https://storage.googleapis.com/voltspot-aa420.appspot.com/fonts/TitilliumWeb-Light.ttf';
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());

    // Embed the Ubuntu font
    pdfDoc.registerFontkit(fontkit);
    const ubuntuFont = await pdfDoc.embedFont(fontBytes);

    // //Change line height
    // const pages = pdfDoc.getPages()
    // pages.forEach(page => {
    //     page.setLineHeight(23)
    // })
    //Change font size
    const pages = pdfDoc.getPages()
    pages.forEach(page => {
        page.setFontSize(9)
    })

        // console.log(formPdfBytes)
        // console.log(typeof(formPdfBytes))
        // console.log(pdfDoc.context.header.toString());
    console.log(data.installation)
//get fields names
        const form = pdfDoc.getForm()
        const fields = form.getFields()
        fields.forEach(field => {
        const type = field.constructor.name
        const name = field.getName()
        console.log(`${type}: ${name}`)
        // if (field.type == 'PDFTextField') {field.setFontSize(9.5)}
        })
//----------------------------------------------

//map form fields
        const mainPhoto = form.getButton('mainPhoto')
        const nettoPrice = form.getTextField('nettoPrice')
        const bruttoPrice = form.getTextField('bruttoPrice')
        const address = form.getTextField('address')
        const client = form.getTextField('client')
        const power = form.getTextField('power')
        const irradiation = form.getTextField('irradiation')
        const contactPerson = form.getTextField('contactPerson')
        const offerNumber = form.getTextField('offerNumber')
        const date = form.getTextField('date')
        const postscriptum = form.getTextField('postscriptum')
        const moduleDescription = form.getTextField('modulDescription')
        const inverterDescription = form.getTextField('inverterDescription')
        const moduleBrand = form.getTextField('modulBrandT')
        const moduleModel = form.getTextField('modulModel')
        const inverterBrand = form.getTextField('inverterBrand')
        const construction = form.getTextField('construction')
        const energyYearly = form.getTextField('energyYearly')
        const VATvalue = form.getTextField('VATvalue')
        const VAT = form.getTextField('VAT')
        // const FIELDNAME = form.getTextField('FIELDNAME')


//set field values
        // mainPhoto.setImage('')
        offerNumber.setText(data.offerNumber)
        date.setText(data.date)
        nettoPrice.setText(data.installation.nettoPrice+' zł')
        VAT.setText(data.installation.vat)
        VATvalue.setText(data.installation.vatValue+' zł')
        bruttoPrice.setText(data.installation.bruttoPrice+' zł')
        power.setText(data.installation.power+'kWp')
        // contactPerson.disableMultiline()
        contactPerson.setText([
                data.salesman.name,
                data.salesman.phone,
                data.salesman.email,
                data.salesman.web,
            ]
        .join('\n')
        )
        address.setText([
                data.installation.address.street,
                data.installation.address.number,
                '\n',
                data.installation.address.postalCode,
                data.installation.address.city,
            ]
        .join(' ')
        )
        client.setText([
            data.client.name, '\n',
            data.client.phone, '\n',
            data.client.email, '\n',
            data.client.address.street,
            data.client.address.number,
            '\n',
            data.client.address.postalCode,
            data.client.address.city,
        ]
        .join(' ')
        )
        moduleBrand.setText(data.installation.module.brand)
        moduleModel.setText(data.installation.module.model)
        inverterBrand.setText(data.installation.inverter.brand)
        construction.setText(data.installation.construction.type)
        
        irradiation.setText(data.installation.irradiation)
        energyYearly.setText(data.installation.energyYearly)


        // PDFDocument.load(formUrl).then(result => {
        //     pdfDoc = result
        //     const pdfBytes = pdfDoc.save()
        // })
        // // const form = pdfDoc.getForm()
        form.updateFieldAppearances(ubuntuFont)
        form.flatten()
        const pdfBytes = await pdfDoc.save()
        download(pdfBytes, "Czyzby.pdf", "application/pdf")
        
    }


    return (
        <button onClick={makePDF}>
            testuj PDFa
        </button>
    )
}

export default PDFFillForm
