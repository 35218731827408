import React, {useContext, useState} from 'react';
import { OfferContext } from '../../providers/offerProvider'
import { DataContext } from '../../providers/dataProvider'



const FinalTune = () => {
  const offerContext = useContext(OfferContext)
  const { moduls } = useContext(DataContext)
  const state = offerContext.offerState
  const dispatch = offerContext.offerDispatch

  const [selModulePower, setSelModulePower] = useState(moduls.find((item) => item.id == state.moduleId).power)


  React.useEffect(() => {
    setSelModulePower(moduls.find((item) => item.id == state.moduleId).power)
    dispatch({type: 'setModuleCount', payload:(Math.floor(state.requestedPower * 1000 / selModulePower))})
    dispatch({type: 'setTruePower', payload: (selModulePower*state.moduleCount)/1000})
    // console.log(console.log(state.requestedPower))
  }, [state.moduleId, state.requestedPower, selModulePower])

  React.useEffect(() => {
    // console.log('modulePower'+selModulePower)
    // console.log('moduleCount: '+state.moduleCount)
    // console.log('truePower PRZED: '+state.truePower)

    dispatch({type: 'setTruePower', payload: (selModulePower*state.moduleCount/1000)})


  }, [state.moduleCount])

  // const [power, setPower] = useState(selModulePower*state.moduleCount/1000)

  // React.useEffect(() => {
  //   console.log('modulePower'+selModulePower)
  //   console.log('moduleCount: '+state.moduleCount)
  //   console.log('truePower PRZED: '+power)

  //   setPower(selModulePower*state.moduleCount/1000)
    
  //   console.log('truePower PO: '+power)

  // }, [state.moduleCount, state.moduleId])


  // console.log('truePower PO: '+state.truePower)


  const handleMinusClick = () => {
    if (state.moduleCount>0) {
      dispatch({type: 'setModuleCount', payload: state.moduleCount -1})
      dispatch({type: 'setRequestedPower', payload: (selModulePower*(state.moduleCount-1)/1000)})
    } else {
    dispatch({type: 'setModuleCount', payload: 0})
    dispatch({type: 'setRequestedPower', payload: 0})
    }
    // dispatch({type: 'setRequestedPower', payload: state.truePower})
  }
  const handlePlusClick = () => {
    // state.requestedPower>50 ? alert('za duzio') : alert('jeszcze')
    if ((state.requestedPower+selModulePower/1000)>50) return
    // (state.requestedPower+selModulePower/1000) > 50 ? dispatch({type: 'setRequestedPower', payload: 50}) :
    dispatch({type: 'setRequestedPower', payload: (selModulePower*(state.moduleCount+1)/1000)})
    dispatch({type: 'setModuleCount', payload: state.moduleCount +1})

    
    // dispatch({type: 'setRequestedPower', payload: state.truePower})
  }


  return (
    <>
      <div className="mx-auto m-2">
          <table className="mx-auto fw-bold mb-2">
            <tbody>
            <tr>
              <td className='text-end pe-2'>
                Obliczona moc instalacji:
              </td>
              <td className='text-start'>
                {state.truePower} kWp
              </td>
            </tr>
            </tbody>
          </table>
          <label className="mx-auto pe-2">Liczba modułów</label>
          <input type="number" value={state.moduleCount} className='mb-2' readOnly/>
          <div className='d-flex justify-content-center'>
          <button
            className="btn btn-outline-warning mx-2 fw-bold"
            onClick={()=>handleMinusClick()}
          >
            odejmij
          </button>
          <button
            className="btn btn-outline-warning mx-2 fw-bold"
            onClick={()=>handlePlusClick()}
          >
            dodaj
          </button>
          </div>
        </div>
    </>
  );
};

export default FinalTune;
