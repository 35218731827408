import React, { useContext } from 'react';

import Navigation from '../../components/navigation/navigation.component'

import OfferData from '../../components/profile/offerData.component'
import Price from '../../components/calculator/price.component'
import Margin from '../../components/profile/margin.component'
import Commission from '../../components/profile/commission.component'
import { OfferContext } from '../../providers/offerProvider'
import { DataContext } from '../../providers/dataProvider'
import Spinner from '../../components/spinner/spinner'
// import OfferDetailsPDF from '../../components/pdfCreator/OfferDetailsPDF';
import PDFFillForm from '../../components/pdfFillForm/PDFFillForm';
import ProfileData from '../../components/profile/profileData';


const Profile = () => {
  const offerContext = useContext(OfferContext)
  const { loading } = useContext(DataContext)
  const state = offerContext.offerState
  const dispatch = offerContext.offerDispatch
  

  if (loading) return <Spinner/>

  
  return (
    <>
      <Navigation />
      <ProfileData/>
      <OfferData/>
      <Price/>
      <Commission />
      <Margin/>
      <PDFFillForm/>
    </>
  );
};
export default Profile;
