import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap-v5'
import { useFirstRender } from '../hooks/useFirstRender'
import firebase from 'firebase/app';
import 'firebase/firestore';

const AddModule = () => {

  const firstRender = useFirstRender()
  const db = firebase.firestore()
  const modulesRef = db.collection('moduls')
  const [show, setShow] = useState(false);
  const [newModule, setNewModule] = useState({
    available: true,
    brand: '',
    datasheet: '',
    description: '',
    height: 0,
    model: '',
    ncrfg: '',
    power: 0,
    powerwarranty: 0,
    price: 0,
    thickness: 0,
    warranty: 0,
    width: 0
  })

  const resetNewModule = () => {
    setNewModule({
      available: true,
      brand: '',
      datasheet: '',
      description: '',
      height: 0,
      model: '',
      ncrfg: '',
      power: 0,
      powerwarranty: 0,
      price: 0,
      thickness: 0,
      warranty: 0,
      width: 0
    })
  }

  const handleClose = () => {
    resetNewModule()
    setShow(false)
  }
  const handleShow = () => setShow(true)

  const handleAdd = () => {
    
    modulesRef.add(newModule)
    resetNewModule()
    setShow(false)
    // console.log(newModule)
  }

  useEffect(() => {
    if (firstRender) return
    // console.log(document.getElementById('available')?.checked)
    // console.log(newModule)
  }, [newModule])
  // db.collection('moduls').add(data)
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Dodaj moduł
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Dodaj moduł</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Producent</span>
          <input type="text" className="form-control" placeholder="Producent" id='brand'
            onChange={(e) => setNewModule({...newModule, brand: e.target.value})} required/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Model</span>
          <input type="text" className="form-control" placeholder="Model" id='model'
            onChange={(e) => setNewModule({...newModule, model: e.target.value})}/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Moc</span>
          <input type="number" className="form-control" placeholder="Moc" id='power'
            onChange={(e) => setNewModule({...newModule, power: 1*e.target.value})}/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Wymiary</span>
          <input type="number" className="form-control" placeholder="Szerokość" id='width'
            onChange={(e) => setNewModule({...newModule, width: 1*e.target.value})}/>
          <input type="number" className="form-control" placeholder="Wysokość" id='height'
            onChange={(e) => setNewModule({...newModule, height: 1*e.target.value})}/>
          <input type="number" className="form-control" placeholder="Grubość" id='thickness'
            onChange={(e) => setNewModule({...newModule, thickness: 1*e.target.value})}/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Gwarancja</span>
          <input type="number" className="form-control" placeholder="Gwarancja" id='warranty'
            onChange={(e) => setNewModule({...newModule, warranty: 1*e.target.value})}/>
          <input type="number" className="form-control" placeholder="Gwarancja na moc" id='powerwarranty'
            onChange={(e) => setNewModule({...newModule, powerwarranty: 1*e.target.value})}/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Cena</span>
          <input type="number" className="form-control" placeholder="Cena" id='price'
            onChange={(e) => setNewModule({...newModule, price: 1*e.target.value})}/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Opis</span>
          <textarea type="text" className="form-control" placeholder="Opis" id='description'
            onChange={(e) => setNewModule({...newModule, description: e.target.value})}/>
        </div>
        <div className="input-group mb-3">
          <div className="input-group-text">
            <input className="form-check-input mt-0" type="checkbox" value='' id='available' checked={newModule.available}
              onChange={(e) => setNewModule({...newModule, available: e.target.checked})}/>
          </div>
          <label className="input-group-text w-25" for='available'>Dostępny</label>
          {/* <input type="text" className="form-control" aria-label="Text input with checkbox"> */}
        </div>
        {/* <div className="input-group mb-3">
          <span className="input-group-text w-25">Opis</span>
          <input type="file" className="form-control" placeholder="Opis" />
        </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Anuluj
          </Button>
          <Button variant="primary" onClick={handleAdd}>
            Dodaj
          </Button>
        </Modal.Footer>
      </Modal>

      {/* show btn Modal */}
{/* <button type="button" classNameName="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button>


<div classNameName="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div classNameName="modal-dialog">
    <div classNameName="modal-content">
      <div classNameName="modal-header">
        <h5 classNameName="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" classNameName="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div classNameName="modal-body">
        ...
      </div>
      <div classNameName="modal-footer">
        <button type="button" classNameName="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" classNameName="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div> */}
    </>
  );
};

export default AddModule;
