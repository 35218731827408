import React, {useContext, useEffect} from 'react';
import { OfferContext } from '../../providers/offerProvider'
import { DataContext } from '../../providers/dataProvider'
import { useFirstRender } from '../../hooks/useFirstRender'

const Inverters = () => {
  const offerContext = useContext(OfferContext)
  const { inverters } = useContext(DataContext)
  const state = offerContext.offerState
  const dispatch = offerContext.offerDispatch
  const firstRender = useFirstRender()

  

  function sortInvertes(a, b) {
    if (1 * a.acpower < 1 * b.acpower) {
      return -1;
    }
    if (1 * a.acpower > 1 * b.acpower) {
      return 1;
    }
    return 0;
  }
  const allInverters = inverters
  .filter((item) => item.available == 1)
  .map((item) => {
    return (
      <option key={item.id} value={item.brand}>
        {item.brand}
      </option>
    );
  });
  const uniqueInvertersBrand = inverters
  .filter((item) => item.available == 1)
  .map((e) => e['brand'])
  .map((e, i, final) => final.indexOf(e) === i && i)
  .filter((obj) => allInverters[obj])
  .map((e) => allInverters[e]);

  const inverterModels = inverters
    .filter(
      (model) =>
        model.available == 1 &&
        model.brand === state.inverterBrand &&
        // model.phase === phase &&
        model.maxdc / 1000 >= state.truePower
    )
    .sort(sortInvertes)
    .map((item) => {
      return (
        <option key={item.id} value={item.id}>
          {item.phase == 1 ? '1-faz | ' : ''} {item.model} | AC:{item.acpower / 1000} kW | max DC:{item.maxdc / 1000} kW
        </option>
      );
    });
    
  

  useEffect(() => {
    if (firstRender) return
    const autoInverter = inverters
    .filter((item) => item.brand == state.inverterBrand)
    .reduce((a,b) => {
      // console.log(a.acpower)
      return Math.abs(b.acpower/1000 - state.truePower) < Math.abs(a.acpower/1000 - state.truePower) ? b : a
    })
    dispatch({type: 'setInverterId', payload: autoInverter.id})
  }, [state.inverterBrand, state.truePower])

  const handleBrandChange = (e)=> {
    dispatch({type: 'setInverterBrand', payload: e.target.value})
    // dispatch({type: 'setInverterId', payload: autoInverter.id})
  }

  const handleModelChange = (e)=> {
    dispatch({type: 'setInverterId', payload: e.target.value})
    // console.log('---------------Zaszła zmiana--------------')
    // dispatch({type: 'setInverterId', payload: autoInverter.id})
  }

  return (
    <div className="mx-auto d-flex flex-column bd-highlight m-3">      
      <div className="mx-auto m-2">
        <label className="mx-auto pe-2">Producent falownika</label>
        <select
          value={state.inverterBrand}
          onChange={(e) => handleBrandChange(e)}
        >
          <optgroup label="Producent">
            <option value="" disabled hidden />
            {uniqueInvertersBrand}
          </optgroup>
        </select>
      </div>
      <div className="mx-auto m-2">
        <label className="mx-auto pe-2">Model falownika</label>
        <select
          value={state.inverterId}
          onChange={(e) => handleModelChange(e)}
        >
        
          <optgroup label="falowniki do wyboru">
            <option value="" disabled hidden />
            {inverterModels}
          </optgroup>
        </select>
      </div>
    </div>
  );
};

export default Inverters;
