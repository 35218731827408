import React, { useContext, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { UserContext } from '../../providers/UserProvider';
import { OfferContext } from '../../providers/offerProvider'
import { DataContext } from '../../providers/dataProvider'



const Margin = () => {
  const offerContext = useContext(OfferContext)
  const { settings, loading } = useContext(DataContext)
  const state = offerContext.offerState
  const dispatch = offerContext.offerDispatch
  const user = useContext(UserContext).user
  const division = user.division
  const db = firebase.firestore()
  const userRef = db.collection('users').doc(user.uid)



  const minMargin = parseInt(settings[division].minMargin)
  

  const setMargin = (margin) =>
    dispatch({ type: 'setMargin', payload: margin });


  // console.log(JSON.stringify(settings))
  // console.log(JSON.stringify(user))
  // console.log('min margin: '+minMargin)
  return (
    <>
    <div className="mx-auto d-flex flex-column bd-highlight m-3">
      <div className="mx-auto d-flex m-2 ">
        <label className="mx-auto pe-2 text-end">Marża</label>
        <input
          type="number"
          value={state.margin}
          min={minMargin}
          onChange={(e) => setMargin(1 * e.target.value)}
          readOnly
        />
      </div>
      <div className='d-flex justify-content-center'>
        <button
          className="btn btn-outline-warning mx-2 fw-bold"
          onClick={() => state.margin>minMargin ? setMargin(state.margin-1) : setMargin(minMargin)}
        >
          odejmij
        </button>
        <button
          className="btn btn-outline-warning mx-2 fw-bold"
          onClick={() => state.margin<100 ? setMargin(state.margin+1) : false}
        >
          dodaj
        </button>
      </div>
      <div className='d-flex justify-content-center m-2'>
        <button
          className="btn btn-outline-primary mx-2 fw-bold"
          onClick={() => userRef.update({margin: state.margin}).then(() => alert('Twoja domyślna marża ustawiona na '+state.margin+'%'))}
        >
          ustaw jako domyślną marżę
        </button>
      </div>
    </div>

      
    </>
  );
};
export default Margin;
