import React, { useContext, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { DataContext } from '../../../providers/dataProvider'
import AddUser from '../../../CSVToFirestore/AddUser'
import EditUser from '../../../CSVToFirestore/EditUser'
import { NavItem } from 'react-bootstrap-v5'


const DatabaseUsers = () => {
  const [moreInfo, setMoreInfo] = useState({});
  const { users } = useContext(DataContext);
  const usersRef = firebase.firestore().collection('users')

  const [editUser, setEditUser] = useState({})

  const toggleEditShow = (id) => {
      setEditUser(users.find((item) => item.id == id))
      setMoreInfo((prevMoreInfo) => ({
         [id]: !prevMoreInfo[id],
      }));
  };

  function sortItems(a, b) {
   if (a.displayName < b.displayName) {
     return -1;
   }
   if (a.displayName > b.displayName) {
     return 1;
   }
   
   return 0;
 }
//   const [newPrice, setNewPrice] = useState()

//   const handlePriceChange = (itemeId) => {
//     const model = users.find((item) => item.id == itemeId).model
//     console.log(model, newPrice)
//     console.log(userssRef.doc(itemeId).price)
//   }

  // const usersList = users
  const usersList = users
   .sort(sortItems)
   .map((item) => {
    return (
      <>
        <tr
         className="container"
        >
         <td className="col mb-2">{item.displayName}</td>
         <td className="col mb-2">{item.phone}</td>
         <td className="col m-2">{item.division}</td>
         <td className='col'>
            {/* <button
               className='btn btn-outline-primary btn-sm'
               onClick={() => {
                  toggleEditShow(item.id);
                }}>
                  edytuj
            </button> */}
            {/* {item.id} */}
            <EditUser uid={item.id} />
         </td>
        </tr>
        {/* {moreInfo[item.id] ? (
         <tr>
            <td>
               <input email="text" 
                  className='form-control form-control-sm'
                  onChange={(e) => setEditUser({...editUser, email: e.target.value})}
                  value={editUser.email}/>
            </td>
            <td>
               <input email="number" 
                  className='form-control form-control-sm'
                  onChange={(e) => setEditUser({...editUser, division: e.target.value})}
                  value={editUser.division}/>
            </td>
            <td>
               <button className='btn btn-outline-success'
                  onClick={() => {usersRef.doc(item.id).set(editUser)
                    toggleEditShow(item.id);
                     }}
               >aktualizuj</button>
               <button className='btn btn-outline-secondary'
                  onClick={() => {
                     toggleEditShow(item.id);
                   }}
               >anuluj</button>
               <button className='btn btn-outline-danger btn-sm mt-3'
                  onClick={() => usersRef.doc(item.id).delete().then(() => {alert('usunąłeś pozycję '+item.email)})}
               >usuń</button>
            </td>
         </tr>
        ) : null} */}
      </>
    );
  });

  return (
    <>
      {/* <AddToFirestore collection="users" /> */}
      <table className="table table-striped table-hover mx-auto">
        <thead>
          <tr>
            <td><AddUser/></td>
          </tr>
          <tr>
          <th scope="col">Imię i nazwisko</th>
          <th scope="col">Numer telefonu</th>
          <th scope="col">Dział</th>
          <th scope='col' colSpan='2'></th>
          </tr>
        </thead>
        <tbody>{usersList}</tbody>
      </table>
    </>
  );
};

export default DatabaseUsers;
