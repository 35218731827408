import React, { useContext } from 'react'
import { UserContext } from '../../providers/UserProvider'

const ProfileData = () => {
    const user = useContext(UserContext).user


    return (
        <div className="card mx-auto m-3 bg-light" style={{width: 18+'rem'}}>
            <div className='card-header'>
                Twój Profil
            </div>
            <div className="card-body">
                <h5 className='card-title'>{user.displayName}</h5>
                <h6>{user.phone}</h6>
                <p className='card-text'>{user.email}</p>
            </div>
        </div>
    )
}

export default ProfileData
