import React, { createContext, useContext } from 'react'
import { reducer } from '../reducerActions/offerActions'
import { UserContext } from './/UserProvider'
import { DataContext } from './dataProvider'
import Spinner from '../components/spinner/spinner'


export const OfferContext = createContext();


const OfferProvider = (props) => {
   const user = useContext(UserContext);
   const { inverters, moduls, loading } = useContext(DataContext)

   // console.log(JSON.stringify(moduls[0].id))
// if (loading) return <Spinner/>


   const [state, dispatch] = React.useReducer(reducer, {
      requestedPower: 10,
      truePower: 0,
      moduleId: '',
      moduleCount: 26,
      inverterBrand: '',
      inverterId: '',
      vatTax: 0.08,
      roofType: '',
      optiBrand: '',
      optiId: '',
      optiCount: 0,
      extras: [],
      nettoPrice: 0,
      margin: 0,
      commission: 0
    });

    // Ustawienia domyślne - do edycji - powinny się pobierać z bazy danych
   React.useEffect(() => {
   dispatch({type: 'setModuleId', payload: "0894vJKWFK919ikPprWF"})
   dispatch({type: 'setRoofType', payload: "slant"})
   dispatch({type: 'setOptiId', payload: "DvEZpwLpi5C3R0rLZthM"})
   dispatch({type: 'setInverterBrand', payload: "SOLPLANET"})
   dispatch({type: 'setInverterId', payload: "18OZVNdUHsDXfr63MTY6"})
   dispatch({type: 'setMargin', payload: user.user.margin})
   },[])

  
   return (
      <OfferContext.Provider value={{offerState: state, offerDispatch: dispatch}}>
         {props.children}
      </OfferContext.Provider>
   )
}

export default OfferProvider
