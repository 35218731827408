import React, {useContext} from 'react';
import { OfferContext } from '../../providers/offerProvider'
// import { DataContext } from '../../providers/dataProvider'

const VatTax = () => {
  const offerContext = useContext(OfferContext)
  // const { moduls } = useContext(DataContext)
  const state = offerContext.offerState
  const dispatch = offerContext.offerDispatch
  

  return (
    <>
      <div className="mx-auto m-2">
          <label className="mx-auto pe-2">Podatek</label>
          <select
          value={state.vatTax}
          onChange={(e) => 
            dispatch({ 
              type: 'setVatTax',
              payload: parseFloat(e.target.value)
            }) }>
            <optgroup label="podatek">
              <option value="" disabled hidden />
              <option value="0.08">8%</option>
              <option value="0.23">23%</option>
            </optgroup>
          </select>
        </div>
    </>
  );
};

export default VatTax;
