import React, { useContext, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
// import AddToFirestore from '../../../CSVToFirestore/AddToFirestore'
import { DataContext } from '../../../providers/dataProvider'
import AddModule from '../../../CSVToFirestore/AddModule'


const DatabaseModuls = () => {
  const [moreInfo, setMoreInfo] = useState({});
  const { moduls } = useContext(DataContext);
  const modulsRef = firebase.firestore().collection('moduls')

  const toggleInfoShow = (id) => {
    setMoreInfo((prevMoreInfo) => ({
      [id]: !prevMoreInfo[id],
    }));
  };

  function sortModules(a, b) {
    if (a.brand < b.brand) {
      return -1;
    }
    if (a.brand > b.brand) {
      return 1;
    }
    if (a.power < b.power) {
      return -1;
    }
    if (a.power > b.power) {
      return 1;
    }
    return 0;
  }

  const [newPrice, setNewPrice] = useState()

  const handlePriceChange = (moduleId) => {
    const model = moduls.find((item) => item.id == moduleId).model
    console.log(model, newPrice)
    modulsRef.doc(moduleId).update({price: parseInt(newPrice)})
  }

  const modulsList = moduls
    .sort(sortModules)
    .map((modul) => {
    return (
      <>
        <tr
          key={modul.id}
          onClick={() => {
            toggleInfoShow(modul.id);
            setNewPrice(modul.price)
            // setAvailable(modul.available)
          }}
          className="container"
        >
          <td className="col mb-2">{modul.brand}</td>
          <td className="col m-2">{modul.model}</td>
          <td className="col m-2">{modul.power} Wp</td>
          <td className="col m-2">{modul.price} zł</td>
          <td className="col m-2">{modul.available ? 
            <span class="badge bg-success">Dostępny</span> : 
            <span class="badge bg-secondary">Niedostępny</span>}</td>
        </tr>
        {moreInfo[modul.id] ? (
          <>
          <tr>
            <td colSpan="5">
              <h5 className="card-title">{modul.brand}</h5>
              <h6 className="card-subtitle mb-2 text-muted">{modul.model}</h6>
              <p className="card-text">{modul.description}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="card-text">moc: {modul.power} Wp</p>
              <p className="card-text">wysokośc: {modul.height} mm<br/>
              szerokość: {modul.width} mm</p>
            </td>
            <td>
              <p className='card-text'>cena: {modul.price} zł</p>
              <p className="card-text">gwarancja: {modul.warranty} lat</p>
            </td>
            <td colSpan='3'>
              <div class="input-group input-group-sm mb-3">
                <input type="number" class="form-control" placeholder="Nowa cena" onChange={(e) => setNewPrice(1*e.target.value)}/>
                <button class="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => handlePriceChange(modul.id)}>Zmień cenę</button>
              </div>
              {modul.available ? 
                <button class='btn btn-success'
                  onClick={() =>
                    modulsRef.doc(modul.id).update({available: false})
                  }>Dostępny</button> : 
                <button class='btn btn-secondary'
                onClick={() =>
                  modulsRef.doc(modul.id).update({available: true})
                }>Niedostępny</button>}
              
            </td>
          </tr>
          </>
        ) : null}
      </>
    );
  });

  return (
    <>
      {/* <AddToFirestore collection="moduls" /> */}
      <table className="table table-striped table-hover w-auto mx-auto">
        <thead>
          <tr>
            <th>
            <AddModule/>
            </th>
          </tr>
          <tr>
          <th scope="col">MARKA</th>
          <th scope="col">MODEL</th>
          <th scope="col">MOC</th>
          <th scope="col">CENA</th>
          <th scope="col">DOSTĘPNOŚĆ</th>
          </tr>
        </thead>
        <tbody>{modulsList}</tbody>
      </table>
    </>
  );
};

export default DatabaseModuls;
