import React from 'react';

const UsersContext = React.createContext();

function useUsers() {
  const context = React.useContext(UsersContext);

  if (!context) {
    throw new Error(`useUsers must be used within a UsersProvider`);
  }
  return context;
}
function UsersProvider(props) {
  const [users, setUsers] = React.useState([]);
  const value = React.useMemo(() => [users, setUsers], [users]);
  return <UsersContext.Provider value={value} {...props} />;
}
export { UsersProvider, useUsers };