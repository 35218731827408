import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal } from 'react-bootstrap-v5'
import { useFirstRender } from '../hooks/useFirstRender'
import firebase from 'firebase/app';
import 'firebase/firestore';
import { DataContext } from '../providers/dataProvider'


const EditUser = (props) => {

  const userid = props.uid
  const firstRender = useFirstRender()
  const db = firebase.firestore()
  const usersRef = db.collection('users')
  const { users } = useContext(DataContext);
  
  const selUser = users.find((item) => item.id == userid)
  
  const [show, setShow] = useState(false);
  const [newUser, setNewUser] = useState(selUser)
  
  const handleClose = () => {
    setShow(false)
  }

  const handleShow = () => {
    setNewUser(selUser)
    setShow(true)
  }

  const handleAdd = () => {
    usersRef.doc(userid).update(newUser)
    setShow(false)
  }


  // useEffect(() => {
  //   if (firstRender) return
  //   // console.log(document.getElementById('available')?.checked)
  //   // console.log(newUser)
  // }, [newUser])
  // db.collection('moduls').add(data)
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Edytuj użytkownika
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Edytuj użytkownika<br/>
            {selUser.displayName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Imię i nazwisko</span>
          <input type="text" className="form-control" placeholder="Imię i nazwisko"
            value={newUser?.displayName ?? ''}
            onChange={(e) => setNewUser({...newUser, displayName: e.target.value})} required/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Numer telefonu</span>
          <input type="text" className="form-control" placeholder="Numer telefonu"
            value={newUser?.phone ?? ''}
            onChange={(e) => setNewUser({...newUser, phone: e.target.value})} required/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">E-mail</span>
          <input type="text" className="form-control" placeholder="e-mail"
            value={newUser?.email ?? ''}
            onChange={(e) => setNewUser({...newUser, email: e.target.value})} required/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Dział</span>
          <input type="text" className="form-control" placeholder="division"
            value={newUser?.division ?? ''}
            onChange={(e) => setNewUser({...newUser, division: e.target.value})} required/>
        </div>
        
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Marża domyślna</span>
          <input type="number" className="form-control" placeholder="Marża"
            value={newUser?.margin ?? ''}
            onChange={(e) => setNewUser({...newUser, margin: 1*e.target.value})}/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Inicjały</span>
          <input type="text" className="form-control" placeholder="Inicjały"
            value={newUser?.prefix ?? ''}
            onChange={(e) => setNewUser({...newUser, prefix: e.target.value})}/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Numer oferty</span>
          <input type="number" className="form-control" placeholder="Numer oferty"
            value={newUser?.curOffer ?? ''}
            onChange={(e) => setNewUser({...newUser, curOffer: e.target.value})}/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text w-25">Numer umowy</span>
          <input type="number" className="form-control" placeholder="Numer umowy"
            value={newUser?.curContract ?? ''}
            onChange={(e) => setNewUser({...newUser, curContract: e.target.value})}/>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Anuluj
          </Button>
          <Button variant="primary" onClick={handleAdd}>
            Zapisz zmiany
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditUser;
